
import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {loginUser} from "../../../actions";
import {compose} from "../../../utils";
import {withTranslation} from "react-i18next";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {Col, Row, Container} from "react-bootstrap";
import MustChangePassword from "../../message-templates/must_change_password";
import TermsAndConditions from "../../message-templates/terms_and_conditions/terms_and_conditions";
import TextMessage from "../../message-templates/text_message";
import TextMessageNoAnswer from "../../message-templates/text_message_no_answer";


class Messages extends Component {

    componentDidMount() {

    }

    render() {
        const {
            userData: {token, messages},
            match: {params}
        } = this.props

        if (!token) {
            return <Redirect to="/"/>
        }

        const currentMessage = messages.getMessageById(params.id, params.level);

        if (currentMessage) {
            switch (currentMessage.template) {
                case 'must_change_password':
                    return (<MustChangePassword props={this.props} data={currentMessage}/>)
                case 'terms_and_conditions':
                    return (<TermsAndConditions props={this.props} data={currentMessage}/>)
                case 'text_message':
                    return (<TextMessage props={this.props} data={currentMessage}/>)
                case 'text_message_no_answer':
                    return (<TextMessageNoAnswer props={this.props} data={currentMessage}/>)
                default:
                    return (
                        <Container>
                            <Row>
                                <Col>
                                    <h1>This is {params.level}</h1>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                </Col>
                            </Row>
                        </Container>
                    );
            }
        }
        return false;
    }
}

const mapStateToProps = ({userData}) => {
    return {userData};
};

const mapDispatchToProps = (dispatch, {tlService}) => {
    return bindActionCreators({
        loginUser: loginUser(tlService)
    }, dispatch);
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(Messages);
