import React from 'react';
import { TLServiceConsumer } from '../tl-api-context';

const withTLService = () => (Wrapped) => {

  return (props) => {
    return (
      <TLServiceConsumer>
        {
          (tlService) => {
            return (<Wrapped {...props}
                             tlService={tlService}/>);
          }
        }
      </TLServiceConsumer>
    );
  }
};

export default withTLService;
