import React from "react";


import {compose, roundWithPrecision} from "../../../utils";
import {withTranslation} from "react-i18next";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";

import ReactApexChart from 'react-apexcharts';
import './clix-chart.css';

class ClixChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            series: [0],
            options: {
                chart: {
                    id: 'clix',
                    type: 'radialBar',
                    offsetY: 0,
                    sparkline: {
                        enabled: true
                    }
                },
                plotOptions: {
                    radialBar: {
                        inverseOrder: false,
                        startAngle: -90,
                        endAngle: 90,
                        offsetX: 0,
                        offsetY: 0,
                        hollow: {
                            margin: 5,
                            size: '50%',
                            background: 'transparent',
                            image: undefined,
                            imageWidth: 150,
                            imageHeight: 150,
                            imageOffsetX: 0,
                            imageOffsetY: 0,
                            imageClipped: true,
                            position: 'front',
                            dropShadow: {
                                enabled: false,
                                top: 0,
                                left: 0,
                                blur: 3,
                                opacity: 0.5
                            }
                        },
                        track: {
                            show: true,
                            startAngle: undefined,
                            endAngle: undefined,
                            background: '#f2f2f2',
                            strokeWidth: '97%',
                            opacity: 1,
                            margin: 0,
                            dropShadow: {
                                enabled: false,
                                top: 0,
                                left: 0,
                                blur: 3,
                                opacity: 0.5
                            }
                        },
                        dataLabels: {
                            show: true,
                            name: {
                                offsetY: -20,
                                color: "Grey",
                                fontSize: "13px"
                            },
                            value: {
                                offsetY: -15,
                                color: "Black",
                                fontSize: "13px",
                                show: true,
                                formatter: function (val) {
                                    return roundWithPrecision(val, 2) + ''
                                }

                            }
                        }
                    }
                },
                grid: {
                    padding: {
                        top: 0,
                        bottom: 30
                    }
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        type: 'horizontal',
                        shade: 'light',
                        shadeIntensity: 0.4,
                        inverseColors: false,
                        opacityFrom: 1,
                        opacityTo: 1,
                        colorStops: [
                            {
                                offset: 0,
                                color: "#EB656F",
                                opacity: 1
                            },
                            {
                                offset: 20,
                                color: "#FAD375",
                                opacity: 1
                            },
                            {
                                offset: 80,
                                color: "#61DBC3",
                                opacity: 1
                            },
                            {
                                offset: 100,
                                color: "#95DA74",
                                opacity: 1
                            }
                        ]
                    },
                },
            labels: ["CLIX"]
            }
        };
    }

    render() {

        return (
            <div id="clix-chart">
                <ReactApexChart name="clix" options={this.state.options} series={[
                    roundWithPrecision(this.props.clix || 0, 2)]} type="radialBar" />
                <div className="from-text">0</div>
                <div className="to-text">100</div>
            </div>

        );
    }
}


const mapStateToProps = ({userData, modalData, openData, adminData}) => {
    return {userData, openData, modalData, adminData};
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps)
)(ClixChart);