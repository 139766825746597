import React, {Component} from "react";

class Default extends Component {
    render() {
        return (
            <div  className="font-smaller">
            default!!!
            </div>
        )
    }
}

export default Default;