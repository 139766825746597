import {Button, Col, Container, Form, Row} from "react-bootstrap";
import React, {Component} from "react";
import {compose} from "../../../utils";
import {withTranslation} from "react-i18next";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {clearUser, userSetAlertState} from "../../../actions";
import {Redirect} from "react-router-dom";


class TextMessage extends Component {

    state = {
        answer: ''
    }

    async handleSubmit() {
        const {
            userData: {token},
            data: {id},
            tlService
        } = this.props;
        const {answer} = this.state;

        try {
            const res = await tlService.setMessageRead(token, id);
            this.props.userData.messages.setRead(id, res.read_time);
            this.props.userSetAlertState(false);
        } catch (e) {
            console.log(e);
            return false;
        }

        if (answer) {
            try {
                await tlService.setMessageAnswer(token, id, answer);
            } catch (e) {
                console.log(e);
                return false;
            }
        }

    }

    logout() {
        this.props.clearUser();
        localStorage.removeItem('token');
        return <Redirect to="/"/>;
    }

    handleChange = (event) => {
        const {name, value} = event.target;
        this.setState({[name]: value});
    };

    render() {
        const {answer} = this.state;
        const {t: translate, data: {text}} = this.props;
        return (
            <Container className="d-flex justify-content-center bg-danger text-black-50 align-items-center w-100 h-100">
                <Row className="mx-auto my-auto">
                    <Col>
                        <Row>
                            <Col md={10}/>
                            <Col md={2}>
                                <Button
                                    onClick={() => this.logout()}
                                    variant="secondary">
                                    <i className="fas fa-sign-out-alt fa-sm fa-fw text-gray-400"/>
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div dangerouslySetInnerHTML={{__html: text}}/>
                            </Col>
                        </Row>
                        <Row className="my-1">
                            <Col>
                                <Form.Group controlId="answer">
                                    <Form.Label>{translate('You can write an answer here')}</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows="4"
                                        name="answer"
                                        value={answer}
                                        onChange={this.handleChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="my-1">
                            <Col>
                                <Button
                                    onClick={() => this.handleSubmit()}
                                    className="d-none d-sm-inline-block btn btn-xs btn-dark shadow-sm">{translate('Answer')}</Button>
                            </Col>

                        </Row>
                    </Col>
                </Row>

            </Container>
        );
    }
}

const
    mapStateToProps = ({userData}) => {
        return {userData};
    };

const
    mapDispatchToProps = (dispatch) => {
        return bindActionCreators({
            userSetAlertState: userSetAlertState(),
            clearUser: clearUser()
        }, dispatch);
    };

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(TextMessage);
