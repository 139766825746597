import React, {Component} from "react";
import Hix from "./templates/hix";
import Clix from "./templates/clix";
import Default from "./templates/default";
import {Row} from "react-bootstrap";

class BenchmarkPanelTemplates extends Component {

    templates = {
        'default': Default,
        'formulaHix': Hix,
        'formulaCLIX': Clix,
    }

    render() {
        const {resultData, enabledBenchmarks} = this.props;

        if (enabledBenchmarks && enabledBenchmarks['settings']) {
            return (
                <Row>
                    {Object.entries(enabledBenchmarks['settings']).map(([key, value]) => {
                        if (this.templates && this.templates[key] &&  resultData[key] !== undefined && value['values']['enabled']) {
                            const InnerPage = this.templates[key];
                            return <InnerPage key={key} value={resultData[key]} resultData={resultData}/>;
                        }
                    })
                    }
                </Row>)
        }
        const InnerPage = this.templates['default'];
        return <InnerPage/>;

    }

}

export default BenchmarkPanelTemplates;