import {Button, Col, Container, Form, Row} from "react-bootstrap";
import React, {Component} from "react";
import {compose} from "../../../utils";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {clearUser, userSetAlertState} from "../../../actions";
import {Redirect} from "react-router-dom";
import config from "../../../config"
import {withTLService} from "../../hoc";


class TermsAndConditions extends Component {


    async handleSubmit() {
        const {
            userData: {token},
            data: {id},

        } = this.props;
        try {
            const res = await this.props.tlService.setMessageRead(token, id);
            this.props.userData.messages.setRead(id, res.read_time);
            this.props.userSetAlertState(false);
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    logout() {
        this.props.clearUser();
        localStorage.removeItem('token');
        return <Redirect to="/"/>;
    }

    render() {
        const {t: translate, i18n} = this.props;
        return (
            <Container className="d-flex justify-content-center bg-danger text-black-50 align-items-center w-100 h-100">
                <Row className="mx-auto my-auto">
                    <Col>
                        <Row>
                            <Col md={10}/>
                            <Col md={2}>
                                <Button
                                    onClick={() => this.logout()}
                                    variant="secondary">
                                    <i className="fas fa-sign-out-alt fa-sm fa-fw text-gray-400"/>
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h2>{translate('Read and approve our Terms and Conditions!')}</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Control
                                    as="textarea"
                                    rows="20"
                                    name="text"
                                    defaultValue={config['termsAndConditionsText'][i18n.language]}
                                />

                            </Col>
                        </Row>
                        <Row className="my-1">
                            <Col>
                                <Button
                                    onClick={() => this.handleSubmit()}
                                    className="d-none d-sm-inline-block btn btn-xs btn-dark shadow-sm">{translate('Agree')}</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Container>
        );
    }
}

const
    mapStateToProps = ({userData}) => {
        return {userData};
    };

const
    mapDispatchToProps = (dispatch) => {
        return bindActionCreators({
            clearUser: clearUser(),
            userSetAlertState: userSetAlertState()
        }, dispatch);
    };

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(TermsAndConditions);
