import React from 'react';
import {Popover} from "react-bootstrap";

const PopupDefault = ({popover}) => {
    const {left, top, data, popId} = popover;
    return (
        <Popover
            id={"popover" + popId}
            style={{left, top, maxWidth: 'none'}}
        >
            <Popover.Title as="h3">default</Popover.Title>
            <Popover.Content>
                {data}
            </Popover.Content>
        </Popover>
    );
};

export default PopupDefault;