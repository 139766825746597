import React from "react";
import {Accordion, Card, Col, Row} from "react-bootstrap";
import ReactBootstrapSlider from "react-bootstrap-slider";
import {roundWithPrecision} from "../../utils";


const PositiveNegativeSection = (props) => {
    const {
        resultData,
        t: translate,
        keySetting,
        showedSetting,
        accOnClose,
        openedSetting
    } = props;

    const countNegative = resultData['dataTonalityNegative'].reduce((prev, curr) => prev + curr["position"].length, 0);
    const countPositive = resultData['dataTonalityPositive'].reduce((prev, curr) => prev + curr["position"].length, 0);

    return (
        <Card key="section_positive_negative">
            <Accordion.Toggle as={Card.Header} eventKey={keySetting}>
                {translate(keySetting)} <strong>{(countPositive - countNegative).toString()}</strong>
            </Accordion.Toggle>
            <Accordion.Collapse onExit={accOnClose}
                                eventKey={keySetting}>
                <Card.Body>

                    <Row>
                        <Col md={10} className="my-auto p-1">
                            <ReactBootstrapSlider
                                value={roundWithPrecision(resultData['formulaTonalityScaleValue'],1)}
                                step={0.1}
                                max={10}
                                min={-10}
                                handle={"triangle"}
                                orientation="horizontal"
                                reversed={false}
                                disabled={"disabled"}
                            />
                        </Col>
                        <Col className="my-auto p-1">
                            <span style={{cursor: "pointer"}} onClick={() => openedSetting('highlightTonality', 'All')}>Highlight It All</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="my-auto p-1">
                            {translate('formulaTonalityScaleValue')}
                            <strong>{roundWithPrecision(resultData['formulaTonalityScaleValue'],1)}</strong>
                        </Col>
                    </Row>
                    <ul>
                        <li
                            className={showedSetting === "dataTonalityNegative" ? "bg-info" : ""}
                            key={'dataTonalityNegative'}
                            onClick={() => openedSetting('dataTonality', 'Negative')}
                        >
                            <Row>
                                <Col md={8}>{translate('Negative')}</Col>
                                <Col>{resultData['dataTonalityNegative'].reduce((prev, curr) => prev + curr["position"].length, 0)}</Col>
                            </Row>
                        </li>
                        <li
                            className={showedSetting === "dataTonalityPositive" ? "bg-info" : ""}
                            key={'dataTonalityPositive'}
                            onClick={() => openedSetting('dataTonality', 'Positive')}
                        >
                            <Row>
                                <Col md={8}>{translate('Positive')}</Col>
                                <Col>{resultData['dataTonalityPositive'].reduce((prev, curr) => prev + curr["position"].length, 0)}</Col>
                            </Row>
                        </li>
                        <li
                            className={showedSetting === "countTonalityClausePositive" ? "bg-info" : ""}
                            key={'countTonalityClausePositive'}
                            onClick={() => openedSetting('countTonalityClause', 'Positive')}
                        >
                            <Row>
                                <Col md={8}>{translate('Clause Positive')}</Col>
                                <Col>{resultData['countTonalityClausePositive'].length}</Col>
                            </Row>
                        </li>
                        <li
                            className={showedSetting === "countTonalityClauseNegative" ? "bg-info" : ""}
                            key={'countTonalityClauseNegative'}
                            onClick={() => openedSetting('countTonalityClause', 'Negative')}
                        >
                            <Row>
                                <Col md={8}>{translate('Clause Negative')}</Col>
                                <Col>{resultData['countTonalityClauseNegative'].length}</Col>
                            </Row>
                        </li>

                        <li
                            className={showedSetting === "countTonalityParagraphPositive" ? "bg-info" : ""}
                            key={'countTonalityParagraphPositive'}
                            onClick={() => openedSetting('countTonalityParagraph', 'Positive')}
                        >
                            <Row>
                                <Col md={8}>{translate('Paragraph Positive')}</Col>
                                <Col>{resultData['countTonalityParagraphPositive'].length}</Col>
                            </Row>
                        </li>
                        <li
                            className={showedSetting === "countTonalityParagraphNegative" ? "bg-info" : ""}
                            key={'countTonalityParagraphNegative'}
                            onClick={() => openedSetting('countTonalityParagraph', 'Negative')}
                        >
                            <Row>
                                <Col md={8}>{translate('Paragraph Negative')}</Col>
                                <Col>{resultData['countTonalityParagraphNegative'].length}</Col>
                            </Row>
                        </li>

                    </ul>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
}

export default PositiveNegativeSection;