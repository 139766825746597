import React  from "react";
import {Accordion, Card, Col, Row} from "react-bootstrap";


const TermsSection = (props) => {
    const {
        resultData,
        t: translate,
        keySetting,
        showedSetting,
        accOnClose,
        openedSetting
    } = props;
    return (
        <Card key='terms_sections'>
            <Accordion.Toggle as={Card.Header} eventKey={keySetting}>
                {translate(keySetting)}
            </Accordion.Toggle>
            <Accordion.Collapse onExit={accOnClose}
                                eventKey={keySetting}>
                <Card.Body>
                    <ul>
                        {Object.entries(resultData['dataTerms']).map(([keyCategory, valCategory]) => {
                            const name = valCategory.category['name'];
                            const length = valCategory['result'].reduce((prev,curr) => prev+curr["position"].length, 0 );
                            return (
                                <li
                                    className={showedSetting === "dataTerms" + keyCategory ? "bg-info" : ""}
                                    key={keyCategory}
                                    onClick={() => openedSetting('dataTerms', keyCategory)}
                                >
                                    <Row>
                                        <Col
                                            md={8}>{name['en']}</Col>
                                        <Col
                                            className="font-weight-bold">{length}</Col>
                                    </Row>
                                </li>
                            );

                        })}
                    </ul>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
}

export default TermsSection;