import React from "react";
import {Accordion, Card, Col, Row} from "react-bootstrap";
import ReactBootstrapSlider from "react-bootstrap-slider";
import {roundWithPrecision} from "../../utils";


const PersonalNeutralSection = (props) => {
    const {
        resultData,
        t: translate,
        keySetting,
        showedSetting,
        accOnClose,
        openedSetting
    } = props;

    const countNegative = resultData['dataTonalityNeutral'].reduce((prev, curr) => prev + curr["position"].length, 0);
    const countPositive = resultData['dataTonalityPersonal'].reduce((prev, curr) => prev + curr["position"].length, 0);
// countScoreNeutral
// countScorePersonal
    return (
        <Card key="section_passive_neutral">
            <Accordion.Toggle as={Card.Header} eventKey={keySetting}>
                {translate(keySetting)} <strong>{countPositive - countNegative}</strong>
            </Accordion.Toggle>
            <Accordion.Collapse onExit={accOnClose}
                                eventKey={keySetting}>
                <Card.Body>

                    <Row>
                        <Col md={10} className="my-auto p-1">
                            <ReactBootstrapSlider
                                value={roundWithPrecision(resultData['formulaPersonalNeutralScaleValue'], 1)}
                                step={0.1}
                                max={10}
                                min={-10}
                                handle={"triangle"}
                                orientation="horizontal"
                                reversed={false}
                                disabled={"disabled"}
                            />
                        </Col>
                        <Col className="my-auto p-1">
                            <span style={{cursor: "pointer"}}
                                  onClick={() => openedSetting('highlightTonalityPN', 'All')}>Highlight It All</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="my-auto p-1">
                            {translate('formulaPersonalNeutralScaleValue')}
                            <strong>{roundWithPrecision(resultData['formulaPersonalNeutralScaleValue'],1)}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="my-auto p-1">
                            {translate('countScoreNeutral')} <strong>{Math.round(resultData['countScoreNeutral']*10)/10}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="my-auto p-1">
                            {translate('countScorePersonal')} <strong>{Math.round(resultData['countScorePersonal']*10)/10}</strong>
                        </Col>
                    </Row>
                    <ul>
                        <li
                            className={showedSetting === "dataTonalityNeutral" ? "bg-info" : ""}
                            key={'dataTonalityNeutral'}
                            onClick={() => openedSetting('dataTonalityPN', 'Neutral')}
                        >
                            <Row>
                                <Col md={8}>{translate('Neutral')}</Col>
                                <Col>{resultData['dataTonalityNeutral'].reduce((prev, curr) => prev + curr["position"].length, 0)}</Col>
                            </Row>
                        </li>
                        <li
                            className={showedSetting === "dataTonalityPersonal" ? "bg-info" : ""}
                            key={'dataTonalityPersonal'}
                            onClick={() => openedSetting('dataTonalityPN', 'Personal')}
                        >
                            <Row>
                                <Col md={8}>{translate('Personal')}</Col>
                                <Col>{resultData['dataTonalityPersonal'].reduce((prev, curr) => prev + curr["position"].length, 0)}</Col>
                            </Row>
                        </li>
                        <li
                            className={showedSetting === "countTonalityClausePersonal" ? "bg-info" : ""}
                            key={'countTonalityClausePersonal'}
                            onClick={() => openedSetting('countTonalityClausePN', 'Personal')}
                        >
                            <Row>
                                <Col md={8}>{translate('Clause Personal')}</Col>
                                <Col>{resultData['countTonalityClausePersonal'].length}</Col>
                            </Row>
                        </li>
                        <li
                            className={showedSetting === "countTonalityClauseNeutral" ? "bg-info" : ""}
                            key={'countTonalityClauseNeutral'}
                            onClick={() => openedSetting('countTonalityClausePN', 'Neutral')}
                        >
                            <Row>
                                <Col md={8}>{translate('Clause Neutral')}</Col>
                                <Col>{resultData['countTonalityClauseNeutral'].length}</Col>
                            </Row>
                        </li>

                        <li
                            className={showedSetting === "countTonalityParagraphPersonal" ? "bg-info" : ""}
                            key={'countTonalityParagraphPersonal'}
                            onClick={() => openedSetting('countTonalityParagraphPN', 'Personal')}
                        >
                            <Row>
                                <Col md={8}>{translate('Paragraph Personal')}</Col>
                                <Col>{resultData['countTonalityParagraphPersonal'].length}</Col>
                            </Row>
                        </li>
                        <li
                            className={showedSetting === "countTonalityParagraphNeutral" ? "bg-info" : ""}
                            key={'countTonalityParagraphNeutral'}
                            onClick={() => openedSetting('countTonalityParagraphPN', 'Neutral')}
                        >
                            <Row>
                                <Col md={8}>{translate('Paragraph Neutral')}</Col>
                                <Col>{resultData['countTonalityParagraphNeutral'].length}</Col>
                            </Row>
                        </li>

                    </ul>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
}

export default PersonalNeutralSection;