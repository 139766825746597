import React from 'react';

const DataViewerHeader = (props) => {

    const {page: {pageHeader}} = props.state;
    return (
        <h1 className="h3 mb-0 text-gray-800">{pageHeader}</h1>
    );
}

export default DataViewerHeader;