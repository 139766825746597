import React, {useState} from 'react';
import {Col, Form, Row, Tab, Tabs} from "react-bootstrap";
import siteLanguages from "../../utils/sitelanguages.json";

export default function NameDescriptionMultilang(props) {

    const {
        translate,
        tName,
        tDescription,
        updateData,
        nameField,
        descriptionField,
        language
    } = props;

    const [tabsLanguage, setTabsLanguage] = useState(language)

    const name =  tName;
    const description =  tDescription;

    const onChangeLanguageTabs = (event) => {
        setTabsLanguage(event);

    }
    const handleChangeName = (event) => {
        const {name: eventName, value} = event.target;
        const [lang, valueName] = eventName.split('|')
        updateData(valueName, {...name, ...{[lang]: value}})
    };

    const handleChangeDescription = (event) => {
        const {name: eventName, value} = event.target;
        const [lang, valueName] = eventName.split('|')
        updateData(valueName, {...description, ...{[lang]: value}})
    };

    return (
        <Row>
            <Col className="my-1">
                <Tabs className="font-smaller" id="language-tab" activeKey={tabsLanguage}
                      onSelect={(k) => onChangeLanguageTabs(k)}>
                    {Object.keys(siteLanguages).map(lang => {
                            return (
                                <Tab key={lang} eventKey={lang} title={siteLanguages[lang]['name']}>
                                    <Row>
                                        <Col className="my-1">
                                            <Form.Control
                                                size={"xs"}
                                                type="text"
                                                name={`${lang}|${nameField[0]}`}
                                                value={name[lang] || ''}
                                                onChange={(e) => {
                                                    handleChangeName(e)
                                                }}
                                                placeholder={translate(nameField[1])}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="my-1">
                                            <Form.Control
                                                size={"xs"}
                                                as="textarea"
                                                rows="3"
                                                name={`${lang}|${descriptionField[0]}`}
                                                value={description[lang] || ''}
                                                onChange={(e) => {
                                                    handleChangeDescription(e)
                                                }}
                                                placeholder={translate(descriptionField[1])}
                                            />
                                        </Col>
                                    </Row>

                                </Tab>
                            )
                        }
                    )}
                </Tabs>
            </Col>
        </Row>
    );
}
