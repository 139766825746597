
class MessagesData {
    constructor(data = [],) {
        this.reloadData(data);
    }

    reloadData(data = []) {
        this.alerts = [];
        this.warnings = [];
        this.infos = [];
        if (data && data.length > 0) {
            for (let tData of data) {
                switch (tData.level) {
                    case 'alert':
                        this.alerts.push(tData);
                        break;
                    case 'warning':
                        this.warnings.push(tData);
                        break;
                    case 'info':
                        this.infos.push(tData);
                        break;
                    default:
                        break;
                }
            }
        }
    }

    hasAlerts() {
        return this.alerts.find(element => !element['read_time'])
    }

    getWarningsAndInfo() {
        if (this.warnings.length > 0 || this.infos.length > 0) {
            const wai = [...this.warnings, ...this.infos]
            return [wai.sort((a, b) => (a.create_time < b.create_time ? -1 : (a.create_time > b.create_time ? 1 : 0))),
                wai.filter((element) => !element.read_time).length]
        }
        return false;
    }

    getMessageById(id, level) {
        switch (level) {
            case 'alert':
                return this.alerts.find(el => el.id === parseInt(id))
            case 'warning':
                return this.warnings.find(el => el.id === parseInt(id))
            case 'info':
                return this.infos.find(el => el.id === parseInt(id))
            default:
                return undefined;
        }
    }


    setRead(id, read_time) {
        const founded = this.alerts.findIndex(element => element['id'] === id)
        this.alerts[founded].read_time = read_time;
        console.log(this.alerts)
    }

}


export default MessagesData
