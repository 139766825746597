import React, {Component} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {compose} from '../../utils';
import {withTLService} from "../hoc";
import {withTranslation} from 'react-i18next';
import {
    ping,
    meUser,
    clearUser,
    getLanguages,
    getCountries,
    getCountryLanguages,
    getLocales,
    getLicenses,
    getUserTypes,
    messagesUser, userSetAlertState, renewUser
} from "../../actions";
import Login from "../pages/login";
import LoginGA from "../pages/login/2fa";
import MainPage from "../elements/main-page";
import Spinner from "../elements/spinner";
import './sb-admin.css';
import '@fortawesome/fontawesome-free/css/all.css'
import 'bootstrap/dist/js/bootstrap.bundle';
import './app.css';
import TextMessage from "../message-templates/text_message";
import TextMessageNoAnswer from "../message-templates/text_message_no_answer";
import TermsAndConditions from "../message-templates/terms_and_conditions/terms_and_conditions";
import MustChangePassword from '../message-templates/must_change_password'

/* IE compatability*/
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';


class App extends Component {

    messagesTik = false
    componentWillUnmount() {
        if (this.messagesTik) {
            console.log('clearInterval');
            clearInterval(this.messagesTik);
            this.messagesTik = false;
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const {userData: {token, user}} = this.props;

        // messages TIK!
        if (token && user['id'] !== undefined) {
            if (!this.messagesTik) {
                this.props.messagesUser(token, user['id']);
                this.messagesTik = setInterval(()=>{
                    const {userData: {token, user, messages}} = this.props;
                    if (token && user['id'] !== undefined && !messages.hasAlerts()) {
                        this.props.messagesUser(token, user['id']);

                        // update token!
/*
                        if (localStorage.getItem('token') && localStorage.getItem('token') === token ) {
                            console.log("actRenewUser remember")
                            this.props.actRenewUser(token,true);
                        } else {
                            console.log("actRenewUser")
                            this.props.actRenewUser(token,false);
                        }
                        */
                    }
                }, 50000);
            }
        } else {
            if (this.messagesTik) {
                clearInterval(this.messagesTik);
                this.messagesTik = false;
            }
        }
    }
    componentDidMount() {
        const {userData: {token, user}} = this.props;
        this.props.getLanguages();
        this.props.getCountries();
        this.props.getCountryLanguages();
        this.props.getLocales();
        this.props.getLicenses();
        this.props.getUserTypes();
        this.props.ping();
        if (token && user['id'] === undefined) {
            this.props.meUser(token);
        }
    }

    loadUserPage(page, match) {
        const {userData: {token, user}} = this.props;
        if (token) {
            if (user['id'] === undefined) {
                return <Spinner/>
            }
            return <MainPage match={match} page={page}/>
        }
        return <Redirect to="/login"/>;
    }

    render() {
        const {userData: { messages}} = this.props;

        const alerts = messages.hasAlerts();
        if (alerts) {
            switch (alerts.template) {
                case 'must_change_password':
                    return (<MustChangePassword props={this.props} data={alerts} />)
                case 'terms_and_conditions':
                    return (<TermsAndConditions props={this.props} data={alerts} />)
                case 'text_message':
                    return (<TextMessage props={this.props} data={alerts} />)
                case 'text_message_no_answer':
                    return (<TextMessageNoAnswer props={this.props} data={alerts} />)
                default:
                    break;
            }
        }

        return (
                <Switch>
                    <Route
                        key="benchmarks"
                        path="/benchmarks"
                        render={({match}) => this.loadUserPage('benchmarks', match)}
                        exact
                    />

                    <Route
                        key="terms"
                        path="/terms"
                        render={({match}) => this.loadUserPage('terms', match)}
                        exact
                    />

                    <Route
                        key="terms_by_category"
                        path="/terms_by_category/:category_id"
                        render={({match}) => this.loadUserPage('terms_by_category', match)}
                        exact
                    />

                    <Route
                        key="benchmark_templates"
                        path="/benchmark/templates"
                        render={({match}) => this.loadUserPage('benchmark_templates', match)}
                        exact
                    />

                    <Route
                        key="messages"
                        path="/messages/:level/:id"
                        render={({match}) => this.loadUserPage('messages', match)}
                        exact
                    />

                    <Route
                        key="dashboard"
                        path="/"
                        render={({match}) => this.loadUserPage('dashboard', match)}
                        exact
                    />
                    <Route
                        key="users"
                        path="/users"
                        render={({match}) => this.loadUserPage('users', match)}
                        exact
                    />
                    <Route
                        key="abbreviations"
                        path="/abbreviations"
                        render={({match}) => this.loadUserPage('abbreviations', match)}
                        exact
                    />
                    <Route
                        key="enduser"
                        path="/enduser"
                        render={({match}) => this.loadUserPage('enduser', match)}
                        exact
                    />

                    <Route
                        key="exceptionwords"
                        path="/exceptionwords"
                        render={({match}) => this.loadUserPage('exceptionwords', match)}
                        exact
                    />
                    <Route
                        key="allowlists"
                        path="/allowlists"
                        render={({match}) => this.loadUserPage('allowlists', match)}
                        exact
                    />
                      <Route
                        key="textbins"
                        path="/textbins"
                        render={({match}) => this.loadUserPage('textbins', match)}
                        exact
                    />
                    <Route
                        key="regexes"
                        path="/regexes"
                        render={({match}) => this.loadUserPage('regexes', match)}
                        exact
                    />
                    <Route
                        key="groups"
                        path="/groups"
                        render={({match}) => this.loadUserPage('groups', match)}
                        exact
                    />
                    <Route
                        key="companies"
                        path="/companies"
                        render={({match}) => this.loadUserPage('companies', match)}
                        exact
                    />
                    <Route
                        key="translations"
                        path="/translations"
                        render={({match}) => this.loadUserPage('translations', match)}
                        exact
                    />
                  <Route
                    key="reports"
                    path="/reports"
                    render={({match}) => this.loadUserPage('reports', match)}
                    exact
                  />
                  <Route
                    key='login-ga'
                    path='/login/2fa/ga'
                    component={LoginGA}
                    exact
                  />
                    <Route
                        key='login'
                        path='/login'
                        component={Login}
                        exact
                    />
                    <Route
                        key='logout'
                        path='/logout'
                        render={() => {
                            this.props.clearUser();
                            localStorage.removeItem('token');
                            return <Redirect to="/"/>;
                        }}
                        exact
                    />
                    <Route
                        key="404"
                        path="/"
                        render={() => <Redirect to="/"/>}
                    />
                </Switch>
        );
    }

}

const mapStateToProps = ({userData, openData}) => {
    return {userData, openData};
};

const mapDispatchToProps = (dispatch, {tlService}) => {
    return bindActionCreators({
        getLanguages: getLanguages(tlService),
        getCountries: getCountries(tlService),
        getLicenses: getLicenses(tlService),
        getUserTypes: getUserTypes(tlService),
        getCountryLanguages: getCountryLanguages(tlService),
        getLocales: getLocales(tlService),
        meUser: meUser(tlService),
        actRenewUser: renewUser(tlService),
        messagesUser: messagesUser(tlService),
        ping: ping(tlService),
        clearUser: clearUser(),
        userSetAlertState: userSetAlertState()
    }, dispatch);
};


export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(App);
