import React, { useState }  from 'react';
import {Button, Col, Form, Popover, Row} from "react-bootstrap";
/*
replacement: Array(1)
0:
description: " 6 "
global_visible: false
lemma: "["bitte","reichen","Sie","noch","folgend","Unterlage","ein",":"]"
status: 0
tag: "["ADV","VVFIN","PPER","ADV","ADJA","NN","PTKVZ","$."]"
words: "["Bitte","reichen","Sie","noch","folgende","Unterlagen","ein",":"]"
                        term: valTerm['term'],
                        replacement: valTerm['replacement'],
                        position: valTerm['position'],
                        currentPosition: i,
*/


const PopupNegativeTerm = (props) => {
    const [repl, setRepl] = useState(0);
    const {
        replaceFromPopup,
        popover: {
            left, top,
            data: {term, replacement, position, currentPosition},
            popId
        }
    } = props;

    console.log('term, replacement, position, currentPosition', term, replacement, position, currentPosition)

    return (
        <Popover
            id={"popover" + popId}
            style={{left, top, maxWidth: 'none'}}
        >
            <Popover.Title as="h3">Negative words replacement suggestion</Popover.Title>
            <Popover.Content>
                <Row>
                    <Col className="font-weight-bold p-2">
                        {JSON.parse(term['words']).join(" ")}
                    </Col>
                </Row>
                <Row>
                    <Col className="font-weight-bold p-2">Suggestion</Col>

                </Row>
                <Row>
                    <Col md={8} className="p-2">
                        <Form.Control
                            size={"xs"}
                            as="select"
                            htmlSize={5}
                            value={repl}
                            onChange={(event) => setRepl(event.target.value)}
                            name="suggestions">
                            {
                                replacement.map((valRep, index) => {
                                    let text;
                                    try {
                                         text = JSON.parse(valRep['words']).join(" ");
                                    } catch (e) {
                                         text = '';
                                    }

                                    return (
                                        <option key={index} value={index}>{text}</option>
                                    );

                                })
                            }
                        </Form.Control>
                    </Col>
                    <Col className=" p-2">
                        <Button
                            onClick={() => replaceFromPopup(popId, repl, 'negative')}
                            variant="secondary">
                            Replace
                        </Button>
                    </Col>
                </Row>
            </Popover.Content>
        </Popover>
    );
};

export default PopupNegativeTerm;
