import React, {Component} from "react";
import {compose} from '../../../utils';
import {withTLService} from "../../hoc";
import {withTranslation} from "react-i18next";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {loginUser} from "../../../actions";

import {Link} from 'react-router-dom';
import LangSet from "../../elements/lang-set";
import MessagesPopup from "../../message-popup";


class TopBar extends Component {

    componentDidMount() {


    }

    render() {
        const {userData: {  user }} = this.props;
        return (
            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                    <i className="fa fa-bars"/>
                </button>
                <div className="row w-100">
                    <div className="mr-auto col-1 ">
                        <LangSet {...this.props}/>
                    </div>
                </div>

                <ul className="navbar-nav ml-auto">

                    <MessagesPopup props={this.props} />


                    <div className="topbar-divider d-none d-sm-block"/>


                    <li className="nav-item dropdown no-arrow">
                        <div className="nav-link dropdown-toggle cursor-pointer"  id="userDropdown" role="button"
                           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className="mr-2 d-none d-lg-inline text-gray-600 small">{user.first_name} {user.last_name} ({user.user_name})</span>
                        </div>

                        <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                             aria-labelledby="userDropdown">
                            <Link className="dropdown-item" to="/profile">
                                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"/>
                                Profile
                            </Link>
                            <div className="dropdown-divider"/>
                            <Link className="dropdown-item" to="/logout" >
                                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"/>
                                Logout
                            </Link>
                        </div>
                    </li>
                </ul>

            </nav>
        );
    }
}

const mapStateToProps = ({userData}) => {
    return {userData};
};

const mapDispatchToProps = (dispatch, {tlService}) => {
    return bindActionCreators({
        loginUser: loginUser(tlService),
    }, dispatch);
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(TopBar);

