import React from 'react';

const {
    Provider : TLServiceProvider,
    Consumer : TLServiceConsumer
} = React.createContext();

export {
    TLServiceProvider,
    TLServiceConsumer
};