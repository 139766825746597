import React, { Component } from 'react'
import {
  compose
} from '../../../utils'
import { withTranslation } from 'react-i18next'
import { withTLService } from '../../hoc'
import { connect } from 'react-redux'
//import TinyEditor from "../../elements/tiny-editor";
//import Dnd from "../../elements/dnd/dnd";
//import mammoth from "mammoth";
import { Col, Form, Container, Row, Button, Alert } from 'react-bootstrap'
//import backendLanguages from "../../../utils/backendlanguages.json";
//import enTags from "./english-tags.json";
//import deTags from "./german-tags.json";

//import HixChart from "../../elements/hix-chart"
//import {Link} from "react-router-dom";

/*
Wenn Sie unser Angebot annehmen, lsenden  Sie  uns Ihre Zusage bitte schriftlich und nennen Sie uns dabei Ihre Kontoverbindung.
Bitte senden Sie uns keine außergerichtlichen Briefe mehr zu dieser Angelegenheit, wenn Sie unser Angebot nicht annehmen möchten.
37
*/

class Dashboard extends Component {
  state = {
    regexp: '',
    replacement: '',
    text: '',
    result: '',
    submitted: false
  }
  handleChange = (event) => {
    const { name, value } = event.target
    this.setState({ [name]: value })
    this.setState({ submitted: false })
  }

  handleReload = async () => {
    const { t, userData: { token }, tlService } = this.props
    let result
    try {
      await tlService.reloadRegex(token)
      result = t('done')
    } catch (e) {
      result = e.toString()
    }
    this.setState({ result })
  }
  handleSubmit = async (event) => {
    const { userData: { token }, tlService } = this.props
    event.preventDefault()
    event.stopPropagation()
    this.setState({ submitted: true })
    const { regexp, text, replacement } = this.state
    if (regexp && text) {
      let result
      try {
        result = await tlService.testRegex(token, regexp, text)
      } catch (e) {
        result = e.toString()
        this.setState({ result })
        return false
      }

      let res = ''
      let repStr = '<br><br>'
      if (result?.data?.result?.length) {
        const rep = {}
        for (let reg of result.data.result) {
          if (rep[`${reg[2]}`])
            rep[`${reg[2]}`] = [...rep[`${reg[2]}`], reg]
          else
            rep[`${reg[2]}`] = [reg]
        }
        for (const i of Object.keys(rep)) {
          res += `(id: ${i}) `
          for (const j in rep[i]) {
            res += `<br>&nbsp;&nbsp;&nbsp;pos:[${rep[i][j][0] - rep[i][j][1] + 1},${rep[i][j][0] + 1}]: ${text.substring(rep[i][j][0] - rep[i][j][1] + 1, rep[i][j][0] + 1)} `
            if (result?.data?.replacements) {
              const currReplacement = result?.data.replacements[i]
              if (currReplacement) {
                for (const replacementKey of Object.keys(currReplacement)) {
                  if (currReplacement[replacementKey][j]) {
                    res += ` $${parseInt(replacementKey) + 1}: ${JSON.stringify(currReplacement[replacementKey][j])} `
                  }
                }
              }
            }
          }
          res += ' <br>'
        }

        if (result?.data?.replacements) {
          let objRep = {}
          try {
            const arrRep = JSON.parse(replacement)
            for (const arrRepElement of arrRep) {
              objRep[arrRepElement[0]] = arrRepElement[1]
            }
          } catch (e) {
            objRep = { '1': replacement }
          }

          const keysRep = Object.keys(rep)
          for (let i = keysRep.length - 1; i >= 0; i--) {
            const currRep = objRep[keysRep[i]]
            let replacedText = text
            for (let j = rep[keysRep[i]].length - 1; j >= 0; j--) {
              const currReplacement = result?.data.replacements[keysRep[i]]
              if (currReplacement) {
                let newCurrRep = currRep
                const repKeys = Object.keys(currReplacement)
                for (let k = repKeys.length - 1; k >= 0; k--) {
                  let replacementKey = repKeys[k]
                  if (currReplacement[replacementKey][j]) {
                    newCurrRep = newCurrRep.replaceAll(`$${parseInt(replacementKey) + 1}`, text.substring(currReplacement[replacementKey][j][0],currReplacement[replacementKey][j][1]+1))
                  }
                }
                replacedText = replacedText.substring(0,rep[keysRep[i]][j][0] - rep[keysRep[i]][j][1] + 1) + newCurrRep + replacedText.substring(rep[keysRep[i]][j][0] + 1)
              }
            }
            repStr += `repacement: (id: ${keysRep[i]})  <b>` + replacedText + '</b><br>'
          }
        }
      }
      result = res + repStr

      this.setState({ result })
    }
  }

  render () {
    const {
      t: translate
    } = this.props
    return (
      <Container className="h-100 d-inline-block" fluid>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">{translate('admin_dashboard_title')}</h1>
        </div>
        <Row>
          <Col><h4>{translate('admin_dashboard_subtitle')}</h4></Col>
        </Row>
        <Form
          name="regex pattern"
          onSubmit={this.handleSubmit}>
          <Row>
            <Col className={'col-md-1 p-1'}>regexp</Col>
            <Col className={'col-md-10 p-1'}><Form.Control
              value={this.state.regexp}
              onChange={this.handleChange}
              name="{translate('admin_dashboard_regexp')}"
              type="text"
              placeholder={translate('admin_dashboard_regexp-sample')}/></Col>
          </Row>
          <Row>
            <Col className={'col-md-1 p-1'}>replacement</Col>
            <Col className={'col-md-10 p-1'}><Form.Control
              value={this.state.replacement}
              onChange={this.handleChange}
              name="{translate('admin_dashboard_replacement')}"
              type="text"
              placeholder={translate('admin_dashboard_replacement-sample')}/></Col>
          </Row>
          <Row>
            <Col className={'col-md-1 p-1'}>text</Col>
            <Col className={'col-md-10 p-1'}><Form.Control
              value={this.state.text}
              onChange={this.handleChange}
              name="{translate('admin_dashboard_text')}"
              type="text"
              placeholder={translate('admin_dashboard_text-sample')}/></Col>
          </Row>
          <Row>
            <Col className={'col-md-2 p-1'}>
              <Button
                type="submit"
                className="btn btn-primary btn-user btn-block">
                {translate('admin_dashboard_check-button')}
              </Button>
            </Col>
          </Row>
        </Form>

        {this.state.result &&
          <Row>
            <Col className={'p-1'}>
              <Alert variant={'primary'}>
                <div dangerouslySetInnerHTML={{ __html: this.state.result }}/>
              </Alert>
            </Col>
          </Row>
        }
      </Container>
    )
  }
}

const mapStateToProps = ({ userData, modalData, openData, adminData }) => {
  return { userData, openData, modalData, adminData }
}

export default compose(
  withTranslation(),
  withTLService(),
  connect(mapStateToProps)
)(Dashboard)
