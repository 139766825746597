import updateUserData from "./user";
import updateOpenData from "./open";
import updateAdminData from "./admin";
import updateModalData from "./modal";

const reducer = (state, action) => {
    return {
        userData: updateUserData(state, action),
        openData: updateOpenData(state, action),
        adminData: updateAdminData(state, action),
        modalData: updateModalData(state, action),
    };
};

export default reducer;
