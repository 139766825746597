import React, {Component} from "react";
import {getDataFromModals, getModalFromModals} from "../../utils";

class BaseModal extends Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {index} = this.props;
        const data = getDataFromModals(this.props, index);
        const prevData = getDataFromModals(prevProps, index);
        if (JSON.stringify(prevData) !== JSON.stringify(data)) {
            this.checkForm();
        }
    }

    handleChangeState = (event, targetName = false, boolValue= false) => {
        const {name, value} = event.target;

        let newValue = value;
        if (boolValue) {
            newValue = !this.state.name;
        }
        if (targetName) {
            this.setState({[targetName]: newValue});
        } else {
            this.setState({[name]: newValue});
        }
    };

    handleKeyPress = (event) => {
        const {index} = this.props;
        const modal = getModalFromModals(this.props, index);
        if (event.charCode === 13 && modal.globalActionFunction !== undefined) {
            modal.globalActionFunction(index);
        }
    }

    handleChange = (event, targetName = false, boolValue= false) => {
        const {name, value} = event.target;
        const {index} = this.props;
        const data = getDataFromModals(this.props, index);

        let newValue = value;
        if (boolValue) {
            newValue = !data[name];
        }

        if (targetName) {
            this.props.modalUpdateData(index, {[targetName]: newValue});
        } else {
            this.props.modalUpdateData(index,{[name]: newValue});
        }
    };

    checkForm = () => {
        return true;
    }


}

export default BaseModal;
