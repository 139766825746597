import {Col, Form, Row} from "react-bootstrap";
import backendLanguages from "../../../utils/backendlanguages.json";
import React from "react";
import NameDescriptionMultilang from "../../modal-elements/name-description-multilang";
import {getDataFromModals} from "../../../utils";


const handleChange = (event, props) => {
    const {name, value} = event.target;
    const {index} = props;
    const data = getDataFromModals(props, index);
    props.modalUpdateData(index, {[name]: value})
};


export default function BenchmarkMainSettings(props) {
    const {index} = props;
    const {text_type_id, locale_name, name, description} = getDataFromModals(props, index);
    const {
        t: translate,
        adminData: {texttype_dir},
        i18n: {language},
    } = props;

    const updateFromNameDescription = (value, newData) => {
        const {index} = props;
        const data = getDataFromModals(props, index);
        props.modalUpdateData(index,{[value]: newData})
    }


    return (
        <Row>
            <Col className="mx-auto my-1 text-center">
                <strong>{translate('Multilanguage support')}</strong>
                <NameDescriptionMultilang
                    nameField={['name', 'Enter name']}
                    descriptionField={['description', 'Enter Description']}
                    tName={name}
                    tDescription={description}
                    sefaultLanguage={props.i18n.language}
                    translate={translate}
                    updateData={(value, data) => updateFromNameDescription(value, data)}
                />
            </Col>
            <Col className="mx-auto my-1 text-center">
                <strong> {translate('Type')}</strong>
                <Row>
                    <Col className="mx-auto my-1">{translate('Text type')}</Col>
                    <Col className="mx-auto my-1">
                        <Form.Control
                            size={"xs"}
                            as="select"
                            value={text_type_id}
                            name="text_type_id"
                            onChange={(e) => {
                                handleChange(e, props)
                            }}>
                            {
                                texttype_dir.map((text) =>
                                    <option key={text}
                                            value={text}>{translate(text)}</option>
                                )
                            }
                        </Form.Control>
                    </Col>
                </Row>
                <Row>
                    <Col className="mx-auto my-1">{translate('Text language')}</Col>
                    <Col className="mx-auto my-1">
                        <Form.Control
                            size={"xs"}
                            as="select"
                            value={locale_name}
                            name="locale_name"
                            onChange={(e) => {
                                handleChange(e, props)
                            }}>
                            {
                                Object.keys(backendLanguages).map(lang =>
                                    <option key={lang}
                                            value={lang}>{backendLanguages[lang]['nativeName']}</option>
                                )
                            }
                        </Form.Control>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}