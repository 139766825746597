import {Col, Container, Form, Row} from "react-bootstrap";
import React from "react";

const   handleChangeBenchmarkTemplate = (event, props) => {
    const {value} = event.target;
    const {modalData: {modal: {data}}, setFromTemplate} = props;
    props.modalUpdateData({benchmark_template: parseInt(value)})
    setFromTemplate(parseInt(value));
}

export default function ChooseBenchmarkTemplateForm(props) {
    const {
        t: translate,
        adminData: {benchmark_templates_dir},
        i18n: {language}
    } = props;

    return (
        <Container>
            <Row>
                <Col>{translate('Choose the Template')}</Col>
            </Row>
            <Row>
                <Col md={5}>
                    <Form.Group controlId="benchmark_template">
                        <Form.Control
                            size={"xs"}
                            as="select"
                            defaultValue={0}
                            name="return_values"
                            onChange={(e) => {
                                handleChangeBenchmarkTemplate(e, props)
                            }}>
                            <option key={0} value={0}>{translate('Choose the Template')}</option>
                            {
                                Object.keys(benchmark_templates_dir).length > 0 && Object.keys(benchmark_templates_dir).map((value) =>
                                    <option key={parseInt(value)}
                                            value={parseInt(value)}>{benchmark_templates_dir[value]['name'][language] || value}</option>
                                )
                            }
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>
        </Container>
    );
}