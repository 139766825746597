import React, {Component} from "react";
import ClixChart from "../../clix-chart";
import {Col, Row} from "react-bootstrap";

class Clix extends Component {
    render() {
        const {value} = this.props;
        return (
                <Col key={'formulaCLIX'}>
                    <ClixChart clix={value}/>
                </Col>
        )
    }
}

export default Clix;