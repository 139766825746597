import MessagesData from "../utils/messages";


const updateUserData = (state, action) => {

    const defaultState = {
        token: false,
        loading: false,
        error: null,
        alertState: false,
        user: {},
        termCategories: [],
        messages: new MessagesData([])
    };

    if (state === undefined) {
        return defaultState;
    }
    switch (action.type) {

        case 'FETCH_TERM_CATEGORIES_REQUEST':
            return {
                ...state.userData,
                termCategories: state.userData.termCategories,
            };
        case 'FETCH_TERM_CATEGORIES_SUCCESS':
            return {
                ...state.userData,
                termCategories: action.payload.reduce((accumulator, currentValue) => {
                    const {id, name, description, category_type, locale_name,
                        global_visible, settings, company_id, group_id, user_id, create_time, delete_time, update_time, parent_id} = currentValue;

                    accumulator[id] = {name, description, category_type, locale_name,
                        global_visible, settings, company_id, group_id, user_id, create_time, delete_time, update_time, parent_id};
                    return accumulator
                }, {}),
                error: null
            };
        case 'FETCH_TERM_CATEGORIES_FAILURE':
            return {
                ...state.userData,
                termCategories: defaultState.termCategories,
            };

        case 'FETCH_MESSAGES_REQUEST':
            return {
                ...state.userData,
                messages: state.userData.messages,
            };
        case 'FETCH_MESSAGES_SUCCESS':
            return {
                ...state.userData,
                messages: new MessagesData(action.payload),
            };
        case 'FETCH_MESSAGES_FAILURE':
            return {
                ...state.userData,
                messages: defaultState.messages,
            };

        case 'FETCH_SIGNUP_REQUEST':
            return {
                user: defaultState.user,
                loading: true,
                error: null
            };
        case 'FETCH_SIGNUP_SUCCESS':
            return {
                ...state.userData,
                ...{
                    user: action.payload,
                    loading: false,
                    error: null
                }
            };
        case 'FETCH_SIGNUP_FAILURE':
            return {
                ...state.userData,
                ...{

                    user: defaultState.user,
                    loading: false,
                    error: action.payload
                }
            };

        case 'FETCH_FORGOTPASSWORD_REQUEST':
            return {
                ...state.userData,
                ...{
                    user: defaultState.user,
                    loading: true,
                    error: null
                }
            };
        case 'FETCH_FORGOTPASSWORD_SUCCESS':
            return {
                ...state.userData,
                ...{
                    user: action.payload,
                    loading: false,
                    error: null
                }
            };
        case 'FETCH_FORGOTPASSWORD_FAILURE':
            return {
                ...state.userData,
                ...{
                    user: defaultState.user,
                    loading: false,
                    error: action.payload
                }
            };

        case 'FETCH_MEUSER_REQUEST':
            return {
                ...state.userData, ...{
                    user: defaultState.user,
                    loading: true,
                    error: null
                }
            };
        case 'FETCH_MEUSER_SUCCESS':
            return {
                ...state.userData, ...{
                    user: action.payload,
                    loading: false,
                    error: null
                }
            };
        case 'FETCH_MEUSER_FAILURE':
            return {
                ...state.userData, ...{
                    token: false,
                    user: defaultState.user,
                    loading: false,
                    error: action.payload
                }
            };

        case 'FETCH_USER_LOGIN_REQUEST':
            return {
                ...state.userData,
                ...{
                    user: defaultState.user,
                    token: false,
                    loading: true,
                    error: null
                }
            };
        case 'FETCH_USER_LOGIN_SUCCESS':
            return {
                ...state.userData,
                ...{
                    user: defaultState.user,
                    token: action.payload.token,
                    loading: false,
                    error: null
                }
            };
        case 'FETCH_USER_LOGIN_FAILURE':
            return {
                ...state.userData,
                ...{
                    user: defaultState.user,
                    token: false,
                    loading: false,
                    error: action.payload
                }
            };

        case 'FETCH_USER_RENEW_SUCCESS':
            return {
                ...state.userData,
                ...{
                    token: action.payload.token,
                    error: null
                }
            };
        case 'FETCH_USER_RENEW_FAILURE':
            return {
                ...state.userData,
                ...{
                    token: false,
                    error: action.payload
                }
            };

        case 'USER_CLEAR':
            return defaultState;

        case 'USER_SET_TOKEN':
            return {
                ...state.userData,
                token: action.payload

            };

        case 'USER_SET_ALERT_STATE':
            return {
                ...state.userData,
                alertState: action.payload

            };

        default:
            return state.userData;
    }
};


export default updateUserData;