import React, {useState} from 'react';
import {Button, Col, Form, Popover, Row} from "react-bootstrap";

const PopupSpellcheck = (props) => {
    const {
        replaceFromPopup,
        popover: {
            data: {text, suggestion},
            left, top, popId
        }
    } = props;

    const [repl, setRepl] = useState(suggestion.length > 0 ? suggestion[0] : '');


    return (
        <Popover
            id={"popover" + popId}
            style={{left, top, maxWidth: 'none'}}
        >
            <Popover.Title as="h3">Spell suggestion</Popover.Title>
            <Popover.Content>
                <Row>
                    <Col className="font-weight-bold p-2">
                        {text}
                    </Col>
                </Row>
                <Row>
                    <Col className="font-weight-bold p-2">Suggestion</Col>

                </Row>
                <Row>
                    <Col md={8} className="p-2">
                        <Form.Control
                            size={"xs"}
                            as="select"
                            htmlSize={5}
                            value={repl}
                            onChange={(event) => setRepl(event.target.value)}
                            name="suggestions">
                            {
                                suggestion.map((valRep) => {
                                    return (
                                        <option key={valRep} value={valRep}>{valRep}</option>
                                    );

                                })
                            }
                        </Form.Control>
                    </Col>
                    <Col className=" p-2">
                        <Button
                            onClick={() => replaceFromPopup(popId, repl, 'spell')}
                            variant="secondary">
                            Replace
                        </Button>
                    </Col>
                </Row>
            </Popover.Content>
        </Popover>
    );
};

export default PopupSpellcheck;