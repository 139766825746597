import React, {Component} from "react";
import {Editor} from '@tinymce/tinymce-react';
import {compose} from "../../../utils";
import {withTranslation} from "react-i18next";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";


class TinyEditor extends Component {

    componentDidMount() {
        //   tinymce.init({});
    }

    render() {
        const {text, handleOnEditorKeyUp, handleOnEditorInit, handleOnEditorChange, handleOnMouseOver, handleOnMouseClick} = this.props;
        return (<div>
                <Editor
                    onEditorChange={(text, editor) => handleOnEditorChange(text, editor)}
                    onKeyUp={(key, editor) => handleOnEditorKeyUp(key, editor)}
                    onInit={(event, editor) => handleOnEditorInit(event, editor)}
                    onMouseOver={(event, editor) => handleOnMouseOver(event, editor)}
                    onMouseUp={(event, editor) => handleOnMouseClick(event, editor)}
                    id='text-handwrite'
                    initialValue=""
                    value={text}
                    init={{
                        width: '100%',
                        plugins: 'print preview paste importcss searchreplace autolink  save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak anchor toc insertdatetime advlist lists imagetools textpattern noneditable help charmap quickbars emoticons',
                       // imagetools_cors_hosts: ['picsum.photos'],
                        menubar: 'file edit view insert format tools table help',
                        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                        toolbar_sticky: true,
                        image_advtab: true,
                        indent: false,
                        entity_encoding: "raw",
                        force_br_newlines : false,
                        force_p_newlines : false,
                        forced_root_block : false,
                        remove_linebreaks : false,
                        content_style: `
.snt {
    background: inherit;
    pointer-events: auto;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: red;
}
.snt:hover {
  background-color: yellow;
}

.blue {
    background: inherit;
    pointer-events: auto;
    cursor: pointer;
    color: Blue
}
.blue:hover {
  background-color: yellow;
}

.green {
    background: inherit;
    pointer-events: auto;
    cursor: pointer;
    color: Green
}

.verbal {
    background: inherit;
    pointer-events: auto;
    cursor: pointer;
    color: Green
}
.verbal2 {
    background: inherit;
    pointer-events: auto;
    cursor: pointer;
    color: Red
}

.green:hover {
  background-color: yellow;
}

.yellow {
    background: inherit;
    pointer-events: auto;
    cursor: pointer;
    color: Yellow
}
.yellow:hover {
  background-color: yellow;
}

.dataTonalityNegative {
    background: inherit;
    pointer-events: auto;
    cursor: pointer;
    color: Red
}
.dataTonalityPositive {
    background: inherit;
    pointer-events: auto;
    cursor: pointer;
    color: Green
}


.countTonalityClausePositive {
 background-color: PaleGreen;
}

.countTonalityClauseNegative {
 background-color: LightPink;
}

.countTonalityParagraphPositive {
 background-color: PaleGreen;
}
.countTonalityParagraphNegative {
 background-color: LightPink;
}

.dataTonalityNeutral {
    background: inherit;
    pointer-events: auto;
    cursor: pointer;
    color: Red
}
.dataTonalityPersonal {
    background: inherit;
    pointer-events: auto;
    cursor: pointer;
    color: Green
}

.countTonalityClausePersonal {
 background-color: PaleGreen;
}

.countTonalityClauseNeutral  {
 background-color: LightPink;
}

.countTonalityParagraphPersonal {
 background-color: PaleGreen;
}
.countTonalityParagraphNeutral  {
 background-color: LightPink;
}

.red {
    background: inherit;
    pointer-events: auto;
    cursor: pointer;
    color: Red
}
.red:hover {
  background-color: yellow;
}


.cla {
    background: inherit;
    pointer-events: auto;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: red;
}
.cla:hover {
  background-color: yellow;
}
.changed {
background-color: #90EE90;
}


`,
                        link_list: [
                            {title: 'My page 1', value: 'http://www.tinymce.com'},
                            {title: 'My page 2', value: 'http://www.moxiecode.com'}
                        ],
                        image_list: [
                            {title: 'My page 1', value: 'http://www.tinymce.com'},
                            {title: 'My page 2', value: 'http://www.moxiecode.com'}
                        ],
                        image_class_list: [
                            {title: 'None', value: ''},
                            {title: 'Some class', value: 'class-name'}
                        ],
                        importcss_append: true,
                        templates: [
                            {
                                title: 'New Table',
                                description: 'creates a new table',
                                content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>'
                            },
                            {
                                title: 'Starting my story',
                                description: 'A cure for writers block',
                                content: 'Once upon a time...'
                            },
                            {
                                title: 'New list with dates',
                                description: 'New List with dates',
                                content: '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>'
                            }
                        ],
                        template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                        template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                        height: 500,
                        image_caption: true,
                        quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                        noneditable_noneditable_class: "mceNonEditable",
                        toolbar_mode: 'sliding',
                        contextmenu: "link image imagetools table",
                    }}
                />

            </div>
        );
    }
}

const mapStateToProps = ({userData, modalData, openData, adminData}) => {
    return {userData, openData, modalData, adminData};
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps)
)(TinyEditor);