import React, {useState} from "react";
import {Button} from "react-bootstrap";
import SearchUGCForm from "../elements/search-ugc-form";


export default function UserRightTable(props) {
    const [tmpListRight, setTmpListRight] = useState([[0], [0], [0]])
    const {
        translate,
        rights,
        updateData,
        rightFieldName
    } = props;


    const deleteRight = (index) => {
        const newT = [...rights];
        newT.splice(index, 1);
        updateData(rightFieldName, newT)
    }

    const addRight = (right, type) => {
        let newListRight = [...tmpListRight];
        if (right !== false) {
            newListRight[type] = right;
        } else {
            newListRight[type] = [0];
        }
        setTmpListRight(newListRight);
    }

    const handleButtonAddRight = () => {
        const reducedList = tmpListRight.reduce(((previousValue, currentValue) => previousValue + currentValue[0]), 0);
        if (reducedList > 0 && rights.findIndex(value => JSON.stringify(value) === JSON.stringify(tmpListRight)) === -1) {
            const newT = [...tmpListRight];
            updateData(rightFieldName, [...rights, newT]);
        }
    }


    return (
        <table className="table table-bordered table-striped table-sm">
            <thead>
            <tr>
                <th className="font-smaller" key='user'>{translate('user')}</th>
                <th className="font-smaller" key='group'>{translate('group')}</th>
                <th className="font-smaller" key='company'>{translate('company')}</th>
                <th className="font-smaller" key='_edit_'>{translate('edit')}</th>
            </tr>
            </thead>
            <tbody>
            {rights.map((value, index) =>
                <tr key={index}>
                    <td key='0'
                        className={"font-smaller " + ((value[0] && value[0][0] > 0) && 'font-weight-bold')}>{(value[0] && value[0][0] > 0) ? value[0][1] : '-'}</td>
                    <td key='1'
                        className={"font-smaller " + ((value[1] &&value[1][0] > 0) && 'font-weight-bold')}>{(value[1] && value[1][0] > 0) ? value[1][1] : '-'}</td>
                    <td key='2'
                        className={"font-smaller " + ((value[2] &&value[2][0] > 0) && 'font-weight-bold')}>{(value[2] && value[2][0] > 0) ? value[2][1] : '-'}</td>
                    <td key={"_edit_"}
                        className="font-smaller">
                        <Button size={"xs"}
                                onClick={() => deleteRight(index)}><i
                            className="fas fa-trash"/></Button>
                    </td>
                </tr>
            )}
            <tr className="custom_centered">
                <td>
                    <SearchUGCForm
                        ugc={0}
                        addRight={(right) => addRight(right, 0)}
                    />
                </td>
                <td>
                    <SearchUGCForm
                        ugc={1}
                        addRight={(right) => addRight(right, 1)}
                    />
                </td>
                <td>
                    <SearchUGCForm
                        ugc={2}
                        addRight={(right) => addRight(right, 2)}
                    />
                </td>
                <td className="my-auto mx-auto">
                    <Button size={"xs"}
                            onClick={() => handleButtonAddRight()}><i
                        className="fas fa-plus"/></Button>
                </td>
            </tr>
            </tbody>

        </table>
    );
}