import {Col, Form, Row} from "react-bootstrap";
import React, {useRef, useState} from "react";
import {compose} from "../../../utils";
import {withTranslation} from "react-i18next";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";

const SearchUGCForm = (props) => {
    const [founded, setFounded] = useState([]);
    const [selected, setSelected] = useState(['', '']);
    const inputSelect = useRef(null);

    const {
        t: translate, ugc
    } = props;

    const handleSearchRight = async (event) => {
        const {value} = event.target;
        const {tlService, ugc, userData: {token}} = props;
        if (value.length > 1) {
            let res = [[], []];
            try {
                switch (ugc) {
                    case 0:
                        res[0] = await tlService.getFilteredUsers(token, {}, {email: `%${value}%`});
                        res[1] = await tlService.getFilteredUsers(token, {}, {user_name: `%${value}%`});
                        break;
                    case 1:
                        res[0] = await tlService.getFilteredGroups(token, {}, {name: `%${value}%`});
                        break;
                    case 2:
                        res[0] = await tlService.getFilteredCompanies(token, {}, {name: `%${value}%`});
                        break;
                    default:
                        return false;
                }
            } catch (e) {
                inputSelect.current.size = 1;
                setSelected(['', '']);
                setFounded([]);
                return false;
            }

            let listResult = [];
            if (res[0] && res[0].data) {
                listResult = [...res[0].data];
            }
            if (res[1] && res[1].data) {
                listResult = listResult.concat(res[1].data.filter(value1 => listResult.findIndex(value2 => value2.id === value1.id) === -1));
            }
            if (listResult.length > 0) {
                inputSelect.current.size = 2;
            } else {
                setSelected(['', '']);
                setFounded([]);
                inputSelect.current.size = 1;
            }
            setFounded(listResult);
        }

    }
    const handleSelectUser = (event) => {
        const {value} = event.target;
        const {addRight} = props;
        const intValue = parseInt(value);
        if (intValue > 0) {
            const foundedRecord = founded.find(value1 => value1.id === parseInt(value));
            const newRight = [parseInt(value), ugc === 0 ? foundedRecord.user_name : foundedRecord.name];
            if (foundedRecord) {
                setSelected(newRight);
                addRight(newRight);
            }
        } else {
            setSelected(['', '']);
            addRight(false);
        }
        inputSelect.current.size = 1;
    }

    return (
        <Row>
            <Col md={6} className="font-smaller text-center my-auto py-2 pl-2">
                <Row>
                    <Col>
                        <Form.Control
                            size={"xs"}
                            type="text"
                            name='add_user_search'
                            defaultValue=''
                            onChange={async (e) => {
                                await handleSearchRight(e)
                            }}
                            placeholder={translate("Start to type here")}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Control
                            size={"xs"}
                            as="select"
                            ref={inputSelect}
                            name='user_search_select'
                            value={selected[0]}
                            onChange={(e) => {
                                handleSelectUser(e)
                            }}
                        >
                            <option key='' value=''>-</option>
                            {founded.map(value => <option key={value.id}
                                                          value={value.id}>{ugc === 0 ? value.user_name : value.name}</option>)}
                        </Form.Control>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

const mapStateToProps = ({userData}) => {
    return {userData};
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps)
)(SearchUGCForm);