import React, {Component} from "react";
import {Col, Row, Tab, Tabs} from "react-bootstrap";
import {compose} from "../../utils";
import {withTranslation} from "react-i18next";
import {withTLService} from "../hoc";
import {connect} from "react-redux";
import StatisticTab from "./statistic-tab";


class ResultTemplates extends Component {

    singleArrayValues = ["moreParagraphsInWords", "morePhrasesInWords"];
    multiArrayValues = ["moreSentencesInBrackets", "moreSentencesInLetters"
        , "moreSentencesInPhrases", "moreSentencesInPrepositions", "moreSentencesInWords"];

    render() {
        const {
            resultTemplate,
            resultData,
            enabledBenchmarks,
            runInEditor,
            restartAnalyse,
            t: translate,
            refreshEditor
        } = this.props;

        return (
            <>
                <Row>
                    <Col>
                        <Tabs
                            defaultActiveKey="statistic"
                            id="all-static-tabs">
                            <Tab eventKey="statistic" title="Statistic">
                                <StatisticTab
                                    refreshEditor={refreshEditor}
                                    restartAnalyse={restartAnalyse}
                                    runInEditor={runInEditor}
                                    enabledBenchmarks={enabledBenchmarks}
                                    resultTemplate={resultTemplate}
                                    resultData={resultData}/>
                            </Tab>
                            <Tab eventKey="profile" title="Debug data">
                                {resultTemplate > 0 &&
                                <Row>
                                    <Col className="p-2">
                                        <ul>
                                            {Object.entries(resultData).map(([key, value]) => {
                                                if (value === undefined || value === null ) {
                                                    return '';
                                                }
                                                return <li key={key} className="font-smaller">
                                                    <Row>
                                                        <Col md={3} className="my-auto p-1">{translate(key)}</Col>
                                                        {(!Array.isArray(value) && typeof value !== 'object') &&
                                                        <Col>{value}</Col>
                                                        }
                                                    </Row>
                                                    <ul>
                                                        {Array.isArray(value) && value.map((value2, index) =>
                                                            <li key={index}>
                                                                <Row>
                                                                    <Col className="overflow-auto">{index} {JSON.stringify(value2)}</Col>
                                                                </Row>
                                                            </li>
                                                        )}
                                                        {(!Array.isArray(value) && typeof value === 'object') && Object.entries(value).map(([key2, value2]) =>
                                                            <li key={key2}>
                                                                <Row>
                                                                    <Col>{key2}</Col>
                                                                    <Col>{JSON.stringify(value2)}</Col>
                                                                </Row>
                                                            </li>
                                                        )}


                                                    </ul>

                                                </li>
                                                // }

                                            })}
                                        </ul>

                                    </Col>
                                </Row>}}
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
                <Row>
                    <Col>

                    </Col>
                </Row>

            </>
        );
    }
}

const mapStateToProps = ({userData, openData, adminData}) => {
    return {userData, openData, adminData};
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps)
)(ResultTemplates);