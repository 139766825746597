import React, {Component} from 'react';
import {compose} from "../../../utils";
import {withTranslation} from "react-i18next";
import {withTLService} from "../../hoc";
import {Col, Form, Row} from "react-bootstrap";
import backendLanguages from "../../../utils/backendlanguages.json";

class BenchmarkChoose extends Component {

    onBenchmarkChange(event) {
        const {setUpState} = this.props;
        setUpState({setBenchmark: event.target.value});
    }

    onTextareaLanguageChange = (event) => {
        const {setUpState} = this.props;
        setUpState({textLanguage: event.target.value});
        setUpState({setBenchmark: 0});
    };

    render() {
        const {
            t: translate,
            i18n: {language},
            setBenchmark,
            textLanguage,
            autoTextLanguage,
            enabledBenchmarks
        } = this.props;

        return (
            <>
                <Row>
                    <Col>
                        <Form.Group controlId="text_lang">
                            <Form.Control
                                onChange={this.onTextareaLanguageChange}
                                name="text_lang"
                                as="select"
                                value={textLanguage || autoTextLanguage}>
                                {
                                    Object.keys(backendLanguages).map(lang =>
                                        <option value={lang}
                                                key={lang}>{backendLanguages[lang]['name']} {lang === autoTextLanguage && ` (${translate("Auto detected")})`}</option>
                                    )
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="change_benchmark">
                            <Form.Control
                                onChange={e => this.onBenchmarkChange(e)}
                                name="text_lang"
                                as="select"
                                value={setBenchmark}
                            >
                                <option value="0">{translate('Choose benchmark')}</option>
                                {
                                    (enabledBenchmarks && enabledBenchmarks.length > 0) && enabledBenchmarks.map(bench =>
                                        <option value={bench.id}
                                                key={bench.id}>{bench['name'][language]}</option>
                                    )
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
            </>
        );
    }
}

export default compose(
    withTranslation(),
    withTLService(),
)(BenchmarkChoose);
