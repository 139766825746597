import React, {Component} from "react";
import HixChart from "../../hix-chart";
import {Col, Row} from "react-bootstrap";

class Hix extends Component {
    render() {
        const {value} = this.props;
        return (
                <Col  key={'formulaHIX'}>
                    <HixChart hix={value}/>
                </Col>

        )
    }
}

export default Hix;